// extracted by mini-css-extract-plugin
export const post_container = "blog-index-module--post_container--3mtAe";
export const post_content = "blog-index-module--post_content--1fbCo";
export const post_item = "blog-index-module--post_item--FK5nY";
export const post_title = "blog-index-module--post_title--WYOnd";
export const post_info = "blog-index-module--post_info--2U1sL";
export const post_date = "blog-index-module--post_date--3QwOf";
export const post_length = "blog-index-module--post_length--2_-40";
export const post_tags = "blog-index-module--post_tags--1NNDF";
export const post_preview = "blog-index-module--post_preview--2V8om";
export const post_excerpt = "blog-index-module--post_excerpt--4Gjbs";
export const pagelinks = "blog-index-module--pagelinks--QSK9b";
export const link = "blog-index-module--link--1gU85";